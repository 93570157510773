<template>
  <div class="auth">
    <div hidden>AUTH {{ setter }}</div>
    <Loading />
  </div>
</template>

<script>
import alerts from "./swalAlerts";
import sessionsAxios from "@/common/api/sessionsAxios";
import { getApp } from "@/common/app.main.js";
import axios from "axios";
export default {
  components: {
    Loading: () => import("@/common/Loading.vue"),
  },
  data: function() {
    return {
      setter: null,
      app: null,
    };
  },
  computed: {
    sstk: function() {
      return this.$route.query.sstk;
    },
    redirect: function() {
      return this.$route.query.redirect;
    },
  },
  methods: {
    getAppLink() {
      const app = getApp(process.env.VUE_APP_KEY_NAME);
      return app.link;
    },
    async initAuth() {
      try {
        const { data } = await sessionsAxios.get(`/auth?sstk=${this.sstk}`);

        this.authResponse(data);
      } catch (error) {
        console.log("error", error);
        alerts.dataInfo({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    async authResponse(data) {
      if (data.token) {
        localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, data.token);
      }

      if (this.app.ipLimit) {
        await this.verifyRequireIp();
      }

      const linkToGo = this.redirect ? this.redirect : this.getAppLink();
      location.href = linkToGo;
    },

    async verifyRequireIp() {
      const setAllowed = (ipAllowed) => localStorage.setItem("ipAllowed", ipAllowed);

      const _token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
      const headers = { [process.env.VUE_APP_TOKEN_NAME]: _token };
      const _host = `${this.app.api}/location`;
      const { data } = await axios.get(_host, { headers });

      if (!data?.isAllowed) {
        return;
      }

      setAllowed(true);
    },
  },
  async mounted() {
    this.app = getApp(process.env.VUE_APP_KEY_NAME);
    this.initAuth();
  },
};
</script>
